import React, { useState, useEffect, useRef, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { format, toZonedTime } from "date-fns-tz";
import { FaArrowDown, FaArrowUp, FaSearch } from "react-icons/fa";
import debounce from 'lodash/debounce';

function EmailList({
  emails,
  onEmailClick,
  setEmails,
  totalEmails,
  setAutoReload,
  reloading
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectItem, setSelected] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(0); // New state for dropdown
  const selectedEmailRef = useRef(null);
  const AUTO_RELOAD_TIME = 1;

  console.log('selectedCategory', selectedCategory)

  const toggleSortOrder = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  useEffect(() => {
    fetchSearchEmails(searchTerm, selectedCategory);
  }, [sortOrder, selectedCategory]); // Add selectedCategory as a dependency

  function getFirstHashString(input) {
    const lines = input.split("\n");
    for (let line of lines) {
      if (line.startsWith("# ")) {
        return line.slice(2).trim();
      }
    }
    return "No Title Found";
  }

  // Debounce search API call
  const debouncedSearch = useCallback(debounce((term, category) => {
    fetchSearchEmails(term, category);
  }, 500), []);

  useEffect(() => {
    if (selectedEmailRef.current) {
      selectedEmailRef.current.classList.add("bg-flash");
      selectedEmailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      const handleAnimationEnd = () => {
        selectedEmailRef.current.classList.remove("bg-flash");
        selectedEmailRef.current.removeEventListener("animationend", handleAnimationEnd);
      };

      selectedEmailRef.current.addEventListener("animationend", handleAnimationEnd);
    }
  }, [sortOrder]);

  const handleSearchTerm = (e) => {
    const term = e.target.value;
    setSearchTerm(() => {
      return term;
    });
    debouncedSearch(term, selectedCategory);
  };

  // Fetch emails with search and category filters
  const fetchSearchEmails = async (term, category) => {
    try {
      setAutoReload(true);
      const response = await fetch(
        `https://api.nomo-earnings.com/emails/get-emails?page=1&limit=20&search=${term || searchTerm}&sortOrder=${sortOrder}&category=${category}`
      );
      const _data = await response.json();
      const { data } = _data;
      setAutoReload(false);
      if (_data.currentPage >= _data.totalPages) {
        setHasMore(false);
      }
      if (data) {
        setEmails(data);
      } else {
        console.error("Error fetching emails:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    } finally {
      setAutoReload(false);
    }
  };

  // Handle category change from dropdown
  const handleCategoryChange = (e) => {

    setSelectedCategory((prevSortOrder) => (e.target.value));
    setPage(1);  // Reset the page when category changes
    // fetchSearchEmails(searchTerm, e.target.value);
  };

  const fetchMoreEmails = async () => {
    if (!hasMore) return;
    setAutoReload(true);
    try {
      const response = await fetch(
        `https://api.nomo-earnings.com/emails/get-emails?page=${page + 1}&limit=20&search=${searchTerm}&sortOrder=${sortOrder}&category=${selectedCategory}`
      );
      const _data = await response.json();
      const { data } = _data;
      if (_data.currentPage >= _data.totalPages) {
        setHasMore(false);
      }
      if (data) {
        setEmails((prevEmails) => [...prevEmails, ...data]);
        setPage((prevPage) => prevPage + 1);
      } else {
        console.error("Error fetching more emails:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching more emails:", error);
    } finally {
      setAutoReload(false);
    }
  };

  const handleEmailClick = (id) => {
    onEmailClick(id);
    setSelected(id);
  };

  return (
    <div className="email-list">
      <div
        className="search-sort-container sticky"
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginBottom: "8px",
          }}
        >
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={(e) => handleSearchTerm(e)}
              className="search-input"
            />
            <FaSearch className="search-icon" />
          </div>

          {/* Dropdown menu for category selection */}
          <select value={selectedCategory} onChange={handleCategoryChange} className="category-dropdown">
            <option value={0}>All</option>
            <option value={1}>Earnings</option>
            <option value={2}>Conference</option>
            <option value={3}>Investor Day</option>
          </select>

          <button className="sort-button" onClick={toggleSortOrder}>
            {sortOrder === "asc" ? (
              <FaArrowUp className="sort-icon" />
            ) : (
              <FaArrowDown className="sort-icon" />
            )}
            Sort Date
          </button>
        </div>
        {totalEmails ? (
          <span style={{ color: "gray" }}>{totalEmails} notes</span>
        ) : (
          <div className="loader" />
        )}
      </div>

      <div>
        <InfiniteScroll
          dataLength={emails.length}
          next={fetchMoreEmails}
          hasMore={hasMore}
          loader={
            reloading ? (
              <div
                className="loader-container"
                style={{ marginBottom: "32px", marginTop: "32px" }}
              >
                <div className="loader" />
              </div>
            ) : (
              ""
            )
          }
          endMessage={
            <p style={{ textAlign: "center", margin: "32px auto" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          height="calc(100vh - 97px)"
        >
          {emails?.length > 0 ? (
            <ul>
              {emails.map((email, index) => (
                <li
                  key={email._id}
                  onClick={() => handleEmailClick(email._id)}
                  ref={email._id === selectItem ? selectedEmailRef : null}
                  style={{
                    backgroundColor: email._id === selectItem && "black",
                    border: email._id === selectItem && "2px solid white",
                    boxShadow:
                      email._id === selectItem &&
                      "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <h3>{getFirstHashString(email?.original)}</h3>
                  <p>
                    {format(
                      toZonedTime(
                        new Date(email.createdAt),
                        "America/New_York"
                      ),
                      "MM/dd/yyyy HH:mm"
                    )}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default EmailList;
